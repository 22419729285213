exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3dXI9{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;-ms-flex-align:end;align-items:flex-end;height:100px;width:100%}@media (orientation:portrait) and (max-width:768px){._3dXI9{height:80px}}@media (orientation:landscape) and (max-height:750px){._3dXI9{height:80px}}._1E_GV{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:90%;height:100px;font-family:Archer SSm A,Archer SSm B,Archer,Times New Roman,serif;font-size:16px;font-size:1rem;font-weight:600;background:-webkit-radial-gradient(2.03% 9.49%,96.09% 2204.51%,hsla(0,0%,100%,.9) 0,hsla(0,0%,100%,.7) 100%);background:-o-radial-gradient(2.03% 9.49%,96.09% 2204.51%,hsla(0,0%,100%,.9) 0,hsla(0,0%,100%,.7) 100%);background:radial-gradient(96.09% 2204.51% at 2.03% 9.49%,hsla(0,0%,100%,.9) 0,hsla(0,0%,100%,.7) 100%);-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);border-radius:12px 12px 0 0}@media (max-width:1200px){._1E_GV{height:90px}}@media (max-width:992px){._1E_GV{height:80px}}@media (max-width:768px){._1E_GV{height:60px}}@media (max-width:480px){._1E_GV{height:60px;width:100%}}._1E_GV ._1zXDq{padding-top:21px;padding-right:20px;font-size:larger}@media (max-width:480px){._1E_GV ._1zXDq{font-size:smaller;padding-top:8px}}@media (max-width:320px){._1E_GV ._1zXDq{display:none}}._1E_GV ._3w1e_{height:70%;padding:10px 20px;-ms-flex-item-align:center;align-self:center}@media (max-width:1200px){._1E_GV ._3w1e_{height:70%}}@media (max-width:992px){._1E_GV ._3w1e_{height:65%}}@media (max-width:768px){._1E_GV ._3w1e_{padding:5px 10px;height:50%}}@media (max-width:480px){._1E_GV ._3w1e_{height:30%;padding:0 10px}}._1E_GV ._26zNo{height:100%}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"footer": "_3dXI9",
	"footerBox": "_1E_GV",
	"fundedByWrapper": "_1zXDq",
	"sponsorItem": "_3w1e_",
	"sponsorIcon": "_26zNo"
};