import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FaGraduationCap, FaBalanceScale, FaGift } from 'react-icons/fa';

import ZLMLogo from '../../../../public/icons/cropped-ZLM-Logo-3.png';

import ShowcaseTemplate from '../ShowcaseTemplate/ShowcaseTemplate';
import StatisticsTemplate from '../ShowcaseTemplate/StatisticsTemplate';
import QuoteTemplate from '../ShowcaseTemplate/QuoteTemplate';
import messages from './messages';

import s from '../ShowcaseTemplate/showcase.scss'; // eslint-disable-line css-modules/no-unused-class

/* const scrollToSlide = slideRef => {
  const topOffset = window.innerWidth <= 1080 ? 75 : 100;

  window.scroll({
    top: slideRef.offsetTop - topOffset,
    left: 0,
    behavior: 'smooth',
  });
}; */

const danubeEducation = {
  a0: (...chunks) => (
    <span
      style={{
        fontWeight: '800',
        fontFamily: "'Gotham Rounded A', sans-serif",
      }}
    >
      {chunks.join('')}
    </span>
  ),
};

class ShowcaseMoodle extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    intl: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);

    this.frontPageRef = React.createRef();
    this.slide1Ref = React.createRef();
  }

  componentDidMount() {
    /*     if (window.location.hash && window.location.hash === '#content') {
      scrollToSlide(this.slide1Ref.current);
    } */
  }

  render() {
    const { intl } = this.props;
    return (
      <div>
        <ShowcaseTemplate
          pages={[
            <div className={`${s.showcasePageContent} ${s.firstPage}`}>
              <Row>
                <Col sm={12} md={4}>
                  <StatisticsTemplate
                    icon={<FaGraduationCap />}
                    text=""
                    mainLabel={<FormattedMessage {...messages.statistics_1} />}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <StatisticsTemplate
                    icon={<FaBalanceScale />}
                    text=""
                    mainLabel={<FormattedMessage {...messages.statistics_2} />}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <StatisticsTemplate
                    icon={<FaGift />}
                    text=""
                    mainLabel={<FormattedMessage {...messages.statistics_3} />}
                  />
                </Col>
              </Row>
              <div>
                <p className={s.statisticsTitle}>
                  <FormattedMessage {...messages.statisticsTitle} />
                </p>

                <div>
                  <a
                    href="/flexquiz.zip"
                    download
                    // href="https://moodle.org/plugins/mod_flexquiz"
                    // target="_blank"
                    // rel="noreferrer"
                  >
                    <button
                      className="btn-orange btn-high btn-large"
                      style={{ padding: '10px 30px' }}
                    >
                      <FormattedMessage {...messages.downloadPlugin} />
                    </button>
                  </a>
                </div>
              </div>
              {/* <div className={s.downButtonContainer}>
                <button
                  onClick={() => {
                    scrollToSlide(this.slide1Ref.current);
                  }}
                >
                  <FaChevronDown />
                </button>
              </div> */}
            </div>,
            <Row className={s.sponsorBox}>
              <Col sm={12} md={6}>
                <p>
                  <FormattedMessage {...messages.collaborationZLM} />
                </p>
              </Col>
              <Col sm={12} md={6}>
                <a
                  href="https://www.lernmanagement.at/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ZLMLogo} alt="" />
                </a>
              </Col>
            </Row>,
            <div className={s.showcasePageContent} ref={this.slide1Ref}>
              <QuoteTemplate
                userImage="/Leonhard_300x300.jpg"
                authorName={<FormattedMessage {...messages.quoteAttribution} />}
                quote={<FormattedMessage {...messages.developerQuote} />}
              />
            </div>,
            <div className={s.showcasePageContent}>
              <div className={s.infographic_1}>
                <img src="/house-path.png" alt="" />
              </div>
              <Row className={s.showcaseText}>
                <Col className={s.row} md={6} mdOffset={1}>
                  <div>
                    <FormattedMessage {...messages.showcaseText_A} />
                  </div>
                  <div>
                    <FormattedMessage {...messages.showcaseText_B} />
                  </div>
                </Col>
                <Col className={s.row} md={6} mdOffset={1}>
                  <div>
                    <FormattedMessage {...messages.showcaseText_C} />
                  </div>
                  <div>
                    <FormattedMessage {...messages.showcaseText_D} />
                  </div>
                </Col>
              </Row>
            </div>,
            <div className={s.showcasePageContent}>
              <div className={s.infographic_2}>
                <img src="/Adventurer.png" alt="" />
              </div>
              <Row className={s.showcaseText}>
                <Col className={s.row} md={6} mdOffset={1}>
                  <div>
                    <FormattedMessage
                      {...messages.showcaseText_2}
                      values={{ danube: <Link to="/">danube.ai </Link> }}
                    />
                  </div>
                  <div>
                    <FormattedMessage {...messages.showcaseText_3} />
                  </div>
                </Col>

                <Col className={s.row} md={6} mdOffset={1}>
                  <div>
                    <FormattedMessage {...messages.showcaseText_4} />
                  </div>
                  <div>
                    {intl.formatMessage(
                      messages.showcaseText_5,
                      danubeEducation,
                    )}
                  </div>
                </Col>
                <div className={s.finalButton}>
                  <a
                    href="/flexquiz.zip"
                    download
                    // href="https://moodle.org/plugins/mod_flexquiz"
                    // target="_blank"
                    // rel="noreferrer"
                  >
                    <button
                      className="btn-orange btn-high btn-large"
                      style={{ padding: '10px 30px' }}
                    >
                      <FormattedMessage {...messages.downloadPlugin} />
                    </button>
                  </a>
                </div>
              </Row>
            </div>,
          ]}
        />
      </div>
    );
  }
}

export default withRouter(injectIntl(withStyles(s)(ShowcaseMoodle)));
