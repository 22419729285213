import React from 'react';
import PropTypes from 'prop-types';

import withStyles from 'isomorphic-style-loader/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Layout.scss';
import Footer from './Footer';

import TopNavigation from '../TopNavigation';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className={s.page}>
        <TopNavigation />
        <div className={s.backgroundImage}>
          <div className={s.belowHeader} key="app-content">
            {this.props.children}
          </div>
          <Footer className={s.footerSpace} key="app-footer" />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Layout);
