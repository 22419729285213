import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './SponsorLogo.scss';
import ffgLogo from '../../../../../public/icons/FFG_Logo_EN_black_1000px.png';
import fhtechnikumLogo from '../../../../../public/FH_Technikum_Wien_logo.png';
import ZLMLogo from '../../../../../public/ZLM-Logo.png';
import oebvLogo from '../../../../../public/icons/logo_oebv.png';

class SponsorLogo extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    me: null,
  };

  render() {
    return (
      <div className={s.footer}>
        <div className={s.footerBox}>
          <p className={s.fundedByWrapper}>Partners:</p>

          <div className={s.sponsorItem}>
            <a
              href="https://www.ffg.at/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={s.sponsorIcon} src={ffgLogo} alt="" />
            </a>
          </div>
          <div className={s.sponsorItem}>
            <a
              href="https://www.lernmanagement.at/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={s.sponsorIcon} src={ZLMLogo} alt="" />
            </a>
          </div>
          <div className={s.sponsorItem}>
            <a
              href="https://www.technikum-wien.at/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={s.sponsorIcon} src={fhtechnikumLogo} alt="" />
            </a>
          </div>
          <div className={s.sponsorItem}>
            <a
              href="https://www.oebv.at/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={s.sponsorIcon} src={oebvLogo} alt="" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(SponsorLogo);
