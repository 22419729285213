exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".G3_73{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;text-align:center;color:#000;font-family:Archer SSm A,Archer SSm B,Archer,Times New Roman,serif;font-size:56px;font-size:3.5rem;font-weight:600;margin-top:27vh;margin-bottom:auto;padding-bottom:20px}@media (max-width:1200px){.G3_73{font-size:3rem;margin-top:29vh;width:800px}}@media (max-width:992px){.G3_73{font-size:2.5rem;margin-top:27vh;width:700px}}@media (max-width:768px){.G3_73{margin-top:24vh;width:500px;font-size:2.2rem}}@media (max-width:480px){.G3_73{font-size:1.5rem;margin-top:22vh;width:400px}}@media (max-width:414px){.G3_73{font-size:1.6rem;margin-top:30vh}}@media (max-width:320px){.G3_73{font-size:1.3rem;margin-top:25vh}}@media (max-height:500px){.G3_73{margin-bottom:20px}}.G3_73 button{margin-top:30px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"landingPageCaption": "G3_73"
};