import { defineMessages } from 'react-intl';

const messages = defineMessages({
  statistics_1: {
    id: 'showcase.moodle.statistics_1',
    defaultMessage: 'Works with any Moodle-Quiz',
    description: 'Label for statistics_1 in statistics module of showcase',
  },
  statistics_2: {
    id: 'showcase.moodle.statistics_2',
    defaultMessage: 'The right question at the right time',
    description: 'Label for statistics_2 statistics module of showcase',
  },
  statistics_3: {
    id: 'showcase.moodle.statistics_3',
    defaultMessage: 'Free Base Version without registration',
    description: 'Label for statistics_3 statistics module of showcase',
  },
  statisticsTitle: {
    id: 'showcase.moodle.statisticsTitle',
    defaultMessage:
      "FlexQuiz: Your Moodle-Quiz, adapted to your students' needs.",
    description: 'Label for "statisticsTitle" statistics module of showcase',
  },
  downloadPlugin: {
    id: 'showcase.moodle.downloadPlugin',
    defaultMessage: 'Download Plugin',
    description: 'Label for "downloadPlugin" button of the showcase',
  },
  collaborationZLM: {
    id: 'showcase.moodle.collaborationZLM',
    defaultMessage:
      'This Plugin was developed together with the team of the ZLM.',
    description: 'Text for collaboration with ZLM section',
  },
  quoteAttribution: {
    id: 'showcase.moodle.quoteAttribution',
    defaultMessage: 'Leonhard Eder, Developer',
    description:
      'Label for author of the quote in quote page of the moodle showcase',
  },
  developerQuote: {
    id: 'showcase.moodle.developerQuote',
    defaultMessage:
      'Before FlexQuiz, all students had to do more or less the same. Not any more – this makes a huge difference in terms of motivation.',
  },
  showcaseTextTitle: {
    id: 'showcase.moodle.showcaseTextTitle',
    defaultMessage: 'Summary',
    description: 'Showcase Title for the text section',
  },
  showcaseText_A: {
    id: 'showcase.moodle.showcaseText_A',
    defaultMessage:
      'The course content you so painstakingly created can now be paced and tailored to your students’ individual needs.',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_B: {
    id: 'showcase.moodle.showcaseText_B',
    defaultMessage:
      'With many features that help you customize and guide the learning experience for any duration you intend. ',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_C: {
    id: 'showcase.moodle.showcaseText_C',
    defaultMessage:
      'Our plugin can adapt the content of your course to the student’s learning flow.',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_D: {
    id: 'showcase.moodle.showcaseText_D',
    defaultMessage:
      '    Have the student learn the right task at the right time. Our plugin can detect which and when.',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_1: {
    id: 'showcase.moodle.showcaseText_1',
    defaultMessage: 'Base Version:',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_2: {
    id: 'showcase.moodle.showcaseText_2',
    defaultMessage:
      'In the free base version we implemented a straight-forward index-card-algorithm: each student has to achieve a fixed number of successful repetitions in each learning cycle. ',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_3: {
    id: 'showcase.moodle.showcaseText_3',
    defaultMessage:
      'You can customize the duration of learning cycles, the repetitions and a lot more. It’s very easy to get started and create your own Flex Quiz.',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_4: {
    id: 'showcase.moodle.showcaseText_4',
    defaultMessage:
      'With the enhanced version you get the full power of our AI: our algorithm analyzes each student performance and creates personal quizzes for everyone. ',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_5: {
    id: 'showcase.moodle.showcaseText_5',
    defaultMessage:
      '<a0>danube.education</a0> knows when it’s enough and your students can move on to the next challenge. Get in touch if you want to aquire a license for the advanced Flex Quiz.',
    description: 'moodle Showcase Text paragraphs',
  },
  showcaseText_6: {
    id: 'showcase.moodle.showcaseText_6',
    defaultMessage:
      'Start by selecting any phone in the list and select "similar products".',
    description: 'moodle Showcase Text paragraphs',
  },
});

export default messages;
