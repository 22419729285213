import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../../routes/notFound';
import ScrollToTop from '../ScrollToTop';
import CookieBanner from '../CookieBanner';

class RouteBundler extends React.Component {
  static propTypes = {
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        component: PropTypes.elementType.isRequired,
      }).isRequired,
    ).isRequired,
    prefix: PropTypes.string,
  };

  static defaultProps = {
    prefix: '',
  };

  /* eslint-disable no-nested-ternary */
  render() {
    const { routes, prefix } = this.props;

    return (
      <ScrollToTop>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.path}
              path={`${prefix}${route.path}`}
              component={route.component}
              exact={route.exact}
            />
          ))}
          <NotFound />
        </Switch>
        <CookieBanner />
      </ScrollToTop>
    );
  }
  /* eslint-enable no-nested-ternary */
}

export default withRouter(RouteBundler);
