import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';

import s from './showcase.scss'; //eslint-disable-line

class StatisticsTemplate extends React.Component {
  static propTypes = {
    mainLabel: PropTypes.element.isRequired,
    icon: PropTypes.element.isRequired,
  };

  render() {
    const { icon, mainLabel } = this.props;

    return (
      <div className={s.statisticsBox}>
        <div className={s.statisticsIcon}>{icon}</div>
        <div className={s.mainLabel}>{mainLabel}</div>
      </div>
    );
  }
}

export default withStyles(s)(StatisticsTemplate);
