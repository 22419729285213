import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import CookieBanner from 'react-cookie-banner';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './cookieBanner.scss';

const messages = defineMessages({
  cookieMessage: {
    id: 'cookieMessage',
    defaultMessage:
      'By continuing to use our website, you are accepting the use of our required Cookies.',
    description: 'Text for Cookie footer message.',
  },
  cookieButton: {
    id: 'cookieButton',
    defaultMessage: 'Accept',
    description: 'Text for Cookie accept button.',
  },
});

class CookieBannerBottom extends React.Component {
  render() {
    return (
      <CookieBanner
        message={<FormattedMessage {...messages.cookieMessage} />}
        buttonMessage={<FormattedMessage {...messages.cookieButton} />}
        onAccept={() => {}}
        dismissOnScroll={false}
        disableStyle
        className={s.cookieBanner}
        cookie="user-has-accepted-cookies"
      />
    );
  }
}

export default withStyles(s)(CookieBannerBottom);
