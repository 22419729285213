import { defineMessages } from 'react-intl';

const messages = defineMessages({
  examples: {
    id: 'footer.examples',
    defaultMessage: 'Examples',
    description: 'Text for footer.',
  },
  aboutUs: {
    id: 'footer.aboutUs',
    defaultMessage: 'About us',
    description: 'Text for footer.',
  },

  contact: {
    id: 'footer.contact',
    defaultMessage: 'Contact',
    description: 'Text for footer.',
  },
  technology: {
    id: 'footer.technology',
    defaultMessage: 'Technology',
    description: 'Text for footer.',
  },
  team: {
    id: 'footer.team',
    defaultMessage: 'Team',
    description: 'Text for footer.',
  },
  press: {
    id: 'footer.press',
    defaultMessage: 'Press',
    description: 'Text for footer.',
  },
  faq: {
    id: 'footer.faq',
    defaultMessage: 'FAQ',
    description: 'Text for footer.',
  },
  privacy: {
    id: 'footer.privacy',
    defaultMessage: 'Privacy',
    description: 'Text for footer.',
  },
  imprint: {
    id: 'footer.imprint',
    defaultMessage: 'Imprint',
    description: 'Text for footer.',
  },
  netflix: {
    id: 'footer.netflix',
    defaultMessage: 'Netflix',
    description: 'Text for footer.',
  },
  exam: {
    id: 'footer.exam',
    defaultMessage: 'Exam',
    description: 'Text for footer.',
  },
  training: {
    id: 'footer.training',
    defaultMessage: 'Employee training',
    description: 'Text for footer.',
  },
  industry: {
    id: 'footer.industry',
    defaultMessage: 'Industry',
    description: 'Text for footer.',
  },
  demos: {
    id: 'footer.demos',
    defaultMessage: 'Demos',
    description: 'Text for footer.',
  },
  jobs: {
    id: 'footer.jobs',
    defaultMessage: 'Job Finder',
    description: 'Text for footer.',
  },
  recruiting: {
    id: 'footer.recruiting',
    defaultMessage: 'Recruiting',
    description: 'Text for footer.',
  },
  phones: {
    id: 'footer.phones',
    defaultMessage: 'Phones',
    description: 'Text for footer.',
  },
  realEstate: {
    id: 'footer.realEstate',
    defaultMessage: 'Real estate',
    description: 'Text for footer.',
  },
  learnMore: {
    id: 'footer.learnMore',
    defaultMessage: 'Learn more',
    description: 'Header text for footer.',
  },
  api: {
    id: 'footer.api',
    defaultMessage: 'API',
    description: 'Text for footer.',
  },
  applications: {
    id: 'footer.applications',
    defaultMessage: 'Applications',
    description: 'Header text for footer',
  },
  partners: {
    id: 'footer.partners',
    defaultMessage: 'Partner: enliteAI',
    description: 'Header text for footer',
  },
  media: {
    id: 'landingPage.media',
    defaultMessage: 'Media',
    description: 'Link Label for media.',
  },
  recruitment: {
    id: 'landingPage.recruitment',
    defaultMessage: 'Recruitment',
    description: 'Link Label for recruitment.',
  },
  eCommerce: {
    id: 'landingPage.eCommerce',
    defaultMessage: 'E-commerce',
    description: 'Link Label for eCommerce.',
  },
});

export default messages;
