import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

function dataIdFromObject(obj) {
  switch (obj.__typename) {
    case 'IntlMessage':
      // Do not use id as identifier for message because it's not unique between languages
      // instead instruct cache to build path and index identifier for cache key
      return null;
    default:
      return defaultDataIdFromObject(obj);
  }
}

export default function createCache() {
  return new InMemoryCache({
    dataIdFromObject,
  });
}
