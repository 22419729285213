const validations = {
  required: value => value,
  email: value =>
    !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)),
  slug: value => value && /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value),
  checkPassword: value =>
    /^(?=.*\d)(?=.*[A-Z])(?=.*[!"§$%&/()=?@#'+~*^+.,;:_-]).{8,}$/.test(value),
};

export default validations;
