import React from 'react';
import { PropTypes, oneOfType } from 'prop-types';
import { Modal } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from '../DefaultModal.scss';

class InfoModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.node.isRequired,
    footer: PropTypes.node,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    className: PropTypes.string,
    dialogClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    backdrop: oneOfType([PropTypes.string, PropTypes.bool]),
    footerClassName: PropTypes.string,
  };

  static defaultProps = {
    show: false,
    backdrop: true,
    title: null,
    footer: null,
    className: s.defaultAlert,
    dialogClassName: s.defaultDialog,
    headerClassName: s.defaultHeader,
    bodyClassName: s.defaultBody,
    footerClassName: s.defaultFooter,
    onHide: () => {},
  };

  render() {
    const {
      title,
      body,
      footer,
      backdrop,
      show,
      onHide,
      className,
      dialogClassName,
      headerClassName,
      bodyClassName,
      footerClassName,
    } = this.props;

    return (
      <Modal
        show={show}
        className={className}
        dialogClassName={dialogClassName}
        backdrop={backdrop}
        onHide={onHide}
        enforceFocus={false}
      >
        <Modal.Header className={headerClassName}>{title}</Modal.Header>
        <Modal.Body className={bodyClassName}>{body || <p>Body</p>}</Modal.Body>
        {footer && (
          <Modal.Footer className={footerClassName}>{footer}</Modal.Footer>
        )}
      </Modal>
    );
  }
}
/* eslint-disable react/no-multi-comp */
class SuccessAlertComp extends React.Component {
  render() {
    return (
      <Alert
        {...this.props}
        className={s.successAlert}
        dialogClassName={s.successDialog}
        headerClassName={s.successHeader}
        bodyClassName={s.successBody}
      />
    );
  }
}

const SuccessAlert = withStyles(s)(SuccessAlertComp);
const Alert = withStyles(s)(InfoModal);
export { SuccessAlert, Alert };
