import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col } from 'react-bootstrap';

import s from './TwoColumnPage.scss';

class TwoColumnPage extends React.Component {
  static propTypes = {
    leftColumnContent: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    rightColumnContent: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    leftColumnContent: null,
    rightColumnContent: null,
  };

  render() {
    const { leftColumnContent, rightColumnContent } = this.props;

    return (
      <div className={s.twoColumnPageContainer}>
        <Row className={s.row}>
          <Col xl={{ span: 3, offset: 1 }}>{leftColumnContent}</Col>
          <Col xl={7}>{rightColumnContent}</Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s)(TwoColumnPage);
