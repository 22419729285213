export const codeStyle = {
  padding: '10px',
  border: 'none',
  background: 'white',
  fontSize: '18px',
};

/* eslint-disable prettier/prettier */

export const exampleHeader= (
`
headers: {
    "authorization": "Bearer/<my-api-key>",
}
`.trim());

export const exampleRequest = (
`
{   
    "uniqueIdentifier": String,
    "requests": [
        {   
            "type": String <= 'initialize' or 'continue'
            "courseId": String,
            "poolId": String,
            "userId": String,
            "cycle": Integer,
            "tasks": [
                { "taskId": String, "score": Float, "position": Integer },
                { "taskId": String, "score": Float, "position": Integer },
                { "taskId": String, "score": Float, "position": Integer },
                ...
            ],
            "timestamp": Timestamp,
            "taskPool": [ String, String, ...],
            "limits": {
                "max": Integer or null
            },
            "ccar": Integer,
            "roundupCycle": false
        }
    ]
}
`.trim());

export const exampleRequestBulk = (
`
{   
    "uniqueIdentifier": String,
    "requests": [
        { "type": String, "courseId": String, "poolId": String, "userId": String, "cycle": Integer, ... },
        { "type": String, "courseId": String, "poolId": String, "userId": String, "cycle": Integer, ... },
        { "type": String, "courseId": String, "poolId": String, "userId": String, "cycle": Integer, ... },
        ...
    ]
}
`.trim());

export const exampleResponseBulk = (
`
[   
    { userId1: response1 },
    { userId2: response2 },
    { userId3: response3 },
    ...
]
`.trim());

export const exampleResponse= (
`
[ 
    {  "taskId": String, "grade": Float, "useInNextTaskGroup": Bool, "position": Integer or null },
    {  "taskId": String, "grade": Float, "useInNextTaskGroup": Bool, "position": Integer or null },
    {  "taskId": String, "grade": Float, "useInNextTaskGroup": Bool, "position": Integer or null },
     ... 
]
`.trim());

/* eslint-enable prettier/prettier */
