import { defineMessages } from 'react-intl';

const messages = defineMessages({
  requestDemoButton: {
    id: 'requestDemo.requestDemoButton',
    defaultMessage: 'Request Demo',
    description: 'Button Label for Request Demo Button.',
  },
  requestDemoTitle: {
    id: 'requestDemo.requestDemoTitle',
    defaultMessage: "Enter your e-mail and we'll get back to you",
    description: 'Title for Request Demo Modal.',
  },
  requestDemoEmail: {
    id: 'requestDemo.requestDemoEmail',
    defaultMessage: 'e-mail',
    description: 'Placeholder for Request Demo Modal email field.',
  },
  cancel: {
    id: 'requestDemo.cancel',
    defaultMessage: 'Cancel',
    description: 'Message for Cancel label',
  },
  submit: {
    id: 'requestDemo.submit',
    defaultMessage: 'Submit',
    description: 'Message for Submit label',
  },
  alertSuccessTitle: {
    id: 'requestDemo.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'requestDemo.alertSuccessMessage',
    defaultMessage:
      'Your inquiry was sent. We will try to reply as soon as possible!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'requestDemo.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'requestDemo.alertErrorMessage',
    defaultMessage: 'Sending the inquiry failed, please try again!',
    description: 'Message for error alert dialog',
  },
});

export default messages;
