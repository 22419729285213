import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import TwoColumnPage from '../TwoColumnPage';
import messages from './messages';

const danubeEducation = {
  a0: (...chunks) => (
    <span
      style={{
        fontWeight: '800',
        fontFamily: "'Gotham Rounded A', sans-serif",
      }}
    >
      {chunks.join('')}
    </span>
  ),
};
class LearnMore extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    intl: PropTypes.any.isRequired,
  };

  render() {
    const { intl } = this.props;
    return (
      <TwoColumnPage
        leftColumnContent={
          <div>
            <h1>
              <FormattedMessage {...messages.globalHeader} />
            </h1>
          </div>
        }
        rightColumnContent={
          <div>
            <h2>
              <FormattedMessage {...messages.header1} />
            </h2>
            <p>{intl.formatMessage(messages.text1_1, danubeEducation)}</p>
            <h2>
              <FormattedMessage {...messages.header2} />
            </h2>
            <p>{intl.formatMessage(messages.text2_1, danubeEducation)}</p>
            <h2>
              <FormattedMessage {...messages.header3} />
            </h2>
            <p>{intl.formatMessage(messages.text3_1, danubeEducation)}</p>
            <h2>
              <FormattedMessage {...messages.header4} />
            </h2>
            <p>
              <FormattedMessage {...messages.text4} />
            </p>
          </div>
        }
      />
    );
  }
}

export default injectIntl(LearnMore);
