import React from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Link } from 'react-router-dom';

import s from './Footer.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';

const ffgLogo = require('../../../../../public/icons/FFG_Logo_EN_white_1000px.png');

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.renderLink = this.renderLink.bind(this);
    this.renderExternalLink = this.renderExternalLink.bind(this);
  }
  // eslint-disable-next-line class-methods-use-this
  renderExternalLink(url, message) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className={s.footerLinkWrapper}>
          <span className={s.footerLink}>
            <FormattedMessage {...message} />
          </span>
        </div>
      </a>
    );
  }
  // eslint-disable-next-line class-methods-use-this
  renderLink(url, message) {
    return (
      <Link to={url}>
        <div className={s.footerLinkWrapper}>
          <span className={s.footerLink}>
            <FormattedMessage {...message} />
          </span>
        </div>
      </Link>
    );
  }

  render() {
    return (
      <div className={s.footerWrapper}>
        <div className={s.footer}>
          <div className={`${layoutStyle.noMargin} ${s.footerContent}`}>
            <div className={s.footerColumn}>
              <img
                src="/danube-edu-logo-horizontal-rgb-invers.png"
                alt=""
                className={s.caption}
              />
            </div>
            <div className={s.centercontainer}>
              <div className={s.innerColumnsWrapper}>
                <div className={s.innerColumn}>
                  <h2>
                    <FormattedMessage {...messages.learnMore} />
                  </h2>
                  {this.renderLink('/api', messages.api)}
                  {this.renderLink('/faq', messages.faq)}
                  <br />
                </div>
                <div className={s.innerColumn}>
                  <h2>
                    <FormattedMessage {...messages.aboutUs} />
                  </h2>
                  {this.renderExternalLink(
                    'https://www.danube.ai/about',
                    messages.team,
                  )}
                  {this.renderExternalLink(
                    'https://www.danube.ai/contact',
                    messages.contact,
                  )}
                  {this.renderExternalLink(
                    'https://www.danube.ai/privacy',
                    messages.privacy,
                  )}
                  {this.renderExternalLink(
                    'https://www.danube.ai/imprint',
                    messages.imprint,
                  )}
                </div>
                <div className={s.footerBoxMobile}>
                  funded by
                  <a
                    href="https://www.ffg.at/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className={s.sponsorIconSmall} src={ffgLogo} alt="" />
                  </a>
                </div>
              </div>
              <div className={`${s.iconLinksContainer}`}>
                <div className={s.socialMediaLinks}>
                  <a
                    className={s.socialMediaIconContainer}
                    href="https://www.facebook.com/danubify"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/socialMedia-facebook.png" alt="" />
                  </a>
                  <a
                    className={s.socialMediaIconContainer}
                    href="https://twitter.com/danubify"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/socialMedia-twitter.png" alt="" />
                  </a>
                  <a
                    className={s.socialMediaIconContainer}
                    href="https://www.instagram.com/danubify"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/socialMedia-instagram.png" alt="" />
                  </a>
                  <a
                    className={s.socialMediaIconContainer}
                    href="https://www.linkedin.com/company/danubify"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/socialMedia-linkedin.png" alt="" />
                  </a>
                  <a
                    className={s.socialMediaIconContainer}
                    href="https://www.xing.com/companies/3dataxgmbh&cokg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/socialMedia-xing.png" alt="" />
                  </a>
                  <a
                    className={s.socialMediaIconContainer}
                    href="https://medium.com/breaking-pad"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/socialMedia-medium.png" alt="" />
                  </a>
                </div>
                <div className={s.companyLinks}>
                  <a
                    className={s.waltzingIconContainer}
                    href="https://www.waltzing.at/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/logo_waltzing_light.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className={s.footerBox}>
              funded by
              <a
                href="https://www.ffg.at/"
                target="_blank"
                rel="noopener noreferrer"
                className={s.sponsorLink}
              >
                <img className={s.sponsorIcon} src={ffgLogo} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(layoutStyle, s)(Footer));
