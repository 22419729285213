exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".uNNgy,.uNNgy:after{border-radius:50%;width:10em;height:10em}.uNNgy{margin:60px auto;font-size:10px;position:relative;text-indent:-9999em;border-top:1.1em solid hsla(0,0%,61%,.2);border-right:1.1em solid hsla(0,0%,61%,.2);border-bottom:1.1em solid hsla(0,0%,61%,.2);border-left:1.1em solid #333;-webkit-transform:translateZ(0);-ms-transform:translateZ(0);transform:translateZ(0);-webkit-animation:-VIvG 1.1s infinite linear;animation:-VIvG 1.1s infinite linear}@-webkit-keyframes -VIvG{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes -VIvG{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"loader": "uNNgy",
	"load8": "-VIvG"
};