import { defineMessages } from 'react-intl';

const messages = defineMessages({
  globalHeader: {
    id: 'learnMore.globalHeader',
    defaultMessage: 'Technology',
    description: 'Header for learn-more page',
  },
  header1: {
    id: 'learnMore.header1',
    defaultMessage: 'Artificial Intelligence in Education',
    description: 'Header for learn-more page',
  },
  text1_1: {
    id: 'learnMore.text1_1',
    defaultMessage: `
    There are two main ways to support learning with artificial intelligence (AI): 
    content-driven and user-driven. 
    Most AI algorithms in education try to interpret and understand the content, e.g. 
    they try to learn physics. <a0>danube.education</a0>, 
    on the other hand, is driven by learning interactions from the user only.
    `,
    description: 'Text for learn-more page',
  },
  header2: {
    id: 'learnMore.header2',
    defaultMessage: 'Completely Content-Independent',
    description: 'Header for learn-more page',
  },
  text2_1: {
    id: 'learnMore.text2_1',
    defaultMessage: `
    Being user-driven, <a0>danube.education</a0> has a major advantage: 
    you can directly use it for any content you like.  
    It’s plug-and-play – any content, any student, any learning process.
    `,
    description: 'Text for learn-more page',
  },
  header3: {
    id: 'learnMore.header3',
    defaultMessage: 'A Personal Learning Recommender',
    description: 'Header for learn-more page',
  },
  text3_1: {
    id: 'learnMore.text3_1',
    defaultMessage: `
    <a0>danube.education</a0> learns from learning events such as a 
    student successfully completing a course with a certain learning order. 
    From that data our AI calculates personal learning steps
     to be achieved for your learning progress.
    `,
    description: 'Text for learn-more page',
  },
  header4: {
    id: 'learnMore.header4',
    defaultMessage: 'Your Individual Needs',
    description: 'Header for learn-more page',
  },
  text4: {
    id: 'learnMore.text4',
    defaultMessage: `
    We want to make sure everyone learns at their own pace - 
    there is no one-fit-all solution.So we devised an algorithm 
    that can detect what content you’re already familiar with and what
     you need to revise a little more. The beauty of it is: 
     where other AI present you with a learning path that is the statistically
      best-trodden by the MAJORITY of users, we find the path that is the best for YOU.
    `,
    description: 'Text for learn-more page',
  },
});

export default messages;
