/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import s from './TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class

const renderNavItems = navItems =>
  navItems.map(navItem => (
    <Nav.Item className={s.navItem}>
      <div className={s.navbarText}>{navItem.content}</div>
    </Nav.Item>
  ));

class TopNavigation extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    homeUrl: PropTypes.string,
    customLogoOnClick: PropTypes.func,
    navItemsLeft: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.any,
        link: PropTypes.string, // either link or subnavs
        subNavs: PropTypes.array, // either link or subnavs
      }),
    ),
    navItemsRight: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.any,
        link: PropTypes.string, // either link or subnavs
        subNavs: PropTypes.array, // either link or subnavs
      }),
    ),
  };

  static defaultProps = {
    homeUrl: '/',
    customLogoOnClick: null,
    navItemsLeft: [],
    navItemsRight: [],
  };

  handleSelect(eventKey) {
    switch (eventKey) {
      case 2.1:
        this.props.logout();
        break;
      default:
    }
  }

  static renderLink(homeUrl, forMobile = false) {
    const className = forMobile ? s.mobileIconWrapper : s.eduIconWrapper;
    const src = forMobile
      ? '/icon-white.png'
      : '/danube-edu-logo-horizontal-rgb-invers.png';
    return (
      <Link to={homeUrl}>
        <div className={className}>
          <img src={src} alt="" />
        </div>
      </Link>
    );
  }

  static renderCustom(customLogoOnClick, forMobile = false) {
    const className = forMobile ? s.mobileIconWrapper : s.eduIconWrapper;
    const src = forMobile
      ? '/icon-white.png'
      : '/danube-edu-logo-horizontal-rgb-invers.png';
    return (
      <Button onClick={customLogoOnClick} className={s.logoWrapperButton}>
        <div className={className}>
          <img src={src} alt="" />
        </div>
      </Button>
    );
  }

  render() {
    const {
      homeUrl,
      customLogoOnClick,
      navItemsLeft,
      navItemsRight,
    } = this.props;

    return (
      <Navbar
        className={s.navbar}
        fixed="top"
        fluid
        collapseOnSelect
        expand="lg"
        onSelect={k => this.handleSelect(k)}
      >
        <Navbar.Brand className={s.logoContainer}>
          {customLogoOnClick
            ? TopNavigation.renderCustom(customLogoOnClick)
            : TopNavigation.renderLink(homeUrl)}
          {customLogoOnClick
            ? TopNavigation.renderCustom(customLogoOnClick, true)
            : TopNavigation.renderLink(homeUrl, true)}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse
          id="navbarScroll"
          style={{ justifyContent: 'flex-end' }}
        >
          {navItemsLeft && <Nav>{renderNavItems(navItemsLeft)}</Nav>}
          {navItemsRight && (
            <Nav className={s.navbarNav}>{renderNavItems(navItemsRight)}</Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withStyles(s)(TopNavigation);
