import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/en';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import App from './components/App';
import createFetch from '../core/createFetch';
import configureStore from '../core/store/configureStore';
import { createApolloClient, link } from '../core/createApolloClient';
import { getIntl } from '../core/store/actions/intl';
import { SentryWrapper } from './components/Sentry';

// Universal HTTP client
const fetch = createFetch(window.fetch, {
  baseUrl: window.App.apiUrl,
});

const apolloClient = createApolloClient();

// Initialize a new Redux store
// http://redux.js.org/docs/basics/UsageWithReact.html
const store = configureStore(window.App.state, {
  apolloClient,
  fetch,
});

// circular dependency hack, adding the link function with the store
apolloClient.link = link(store);

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },
  // For react-apollo
  client: apolloClient,
  store,
  // Universal HTTP client
  fetch,
  // intl instance as it can be get with injectIntl
  intl: store.dispatch(getIntl()),
};

const container = document.getElementById('app');
let initialRender = true;

const render = () => {
  const renderReactApp = initialRender ? ReactDOM.hydrate : ReactDOM.render;
  initialRender = false;
  renderReactApp(
    <AppContainer>
      <SentryWrapper client={apolloClient}>
        <StyleContext.Provider value={{ insertCss: context.insertCss }}>
          <BrowserRouter>
            <App context={context} />
          </BrowserRouter>
        </StyleContext.Provider>
      </SentryWrapper>
    </AppContainer>,
    container,
  );
};

export default function main() {
  render();
}

// globally accessible entry point
window.RSK_ENTRY = main;

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./components/App', () => {
    render();
  });
}
