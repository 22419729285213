import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Nav } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';

import TopNavigation from './TopNavigation';
import s from './TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class
import RequestDemoButton from '../RequestDemoButton';
import messages from './messages';

const navItemsRight = [
  {
    key: 'technology',
    content: (
      <Nav.Link className={s.link} href="/learn-more">
        <div className={s.navItemLabel}>
          <FormattedMessage {...messages.technology} />
        </div>
      </Nav.Link>
    ),
  },
  {
    key: 'moodle-plugin',
    content: (
      <Nav.Link className={s.link} href="/moodle-plugin">
        <div className={s.navItemLabel}>
          <FormattedMessage {...messages.moodlePlugin} />
        </div>
      </Nav.Link>
    ),
    minWidth: '170px',
  },
  {
    key: 'requestDemoButton',
    content: <RequestDemoButton isTopNav />,
    minWidth: '160px',
  },
  {
    key: 'danubeLink',
    content: (
      <Nav.Item>
        <a
          className={s.link}
          href="https://www.danube.ai/"
          rel="noreferrer"
          target="_blank"
        >
          <div className={s.navItemLabel}>danube.ai</div>
        </a>
      </Nav.Item>
    ),
  },
];

class TopNavigationWrapper extends React.Component {
  static propTypes = {
    meData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    customLogoOnClick: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    customLogoOnClick: null,
  };

  constructor(props) {
    super(props);

    this.goToLink = this.goToLink.bind(this);
  }

  goToLink({ link }) {
    this.props.history.push(link);
  }

  render() {
    const { customLogoOnClick } = this.props;

    // FIXME: pages wrapped with access tracking somehow
    // display two login buttons

    return (
      <TopNavigation
        homeUrl="/"
        customLogoOnClick={customLogoOnClick}
        navItemsRight={navItemsRight}
      />
    );
  }
}

export default (withRouter, withStyles(s))(TopNavigationWrapper);
