exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1V52y{width:100%;font-family:Gotham SSm A,Gotham SSm B,HelveticaNeue-light,sans-serif;color:#00293e}@media (max-width:768px){._1V52y{font-size:16px;padding:15px}}@media (min-width:768px){._1V52y{font-size:17px;padding:25px 50px}}@media (min-width:1200px){._1V52y{padding:50px}}@media (min-width:1500px){._1V52y{padding:50px 200px}}._1V52y ._1B0YO{margin:0;background:hsla(0,0%,100%,.75);border-radius:12px;padding:75px 20px}@supports ((-webkit-backdrop-filter:none) or (backdrop-filter:none)){._1V52y ._1B0YO{background:-webkit-radial-gradient(2.03% 9.49%,96.09% 2204.51%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);background:-o-radial-gradient(2.03% 9.49%,96.09% 2204.51%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);background:radial-gradient(96.09% 2204.51% at 2.03% 9.49%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}}@media (max-width:768px){._1V52y ._1B0YO{padding:25px 15px}}._1V52y h1{margin:0;margin-bottom:40px;font-family:Archer SSm A,Archer SSm B,Times New Roman,sans-serif;font-style:normal;font-weight:700;line-height:normal;color:#00293e}@media (max-width:768px){._1V52y h1{font-size:30px}}@media (min-width:768px){._1V52y h1{font-size:35px}}@media (min-width:992px){._1V52y h1{font-size:40px;margin-bottom:60px}}._1V52y h2{margin-top:40px;margin-bottom:30px;font-family:Archer SSm A,Archer SSm B,Times New Roman,sans-serif;font-style:normal;font-weight:700;line-height:normal;color:#00293e}@media (max-width:768px){._1V52y h2{font-size:20px}}@media (min-width:768px){._1V52y h2{font-size:25px}}@media (min-width:992px){._1V52y h2{margin-top:60px;font-size:30px}}@media (min-width:1200px){._1V52y h2{margin-top:80px}}._1V52y h2:first-child{margin-top:10px}@media (max-width:768px){._1V52y h2:first-child{margin-top:0}}._1V52y a{font-weight:700}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"twoColumnPageContainer": "_1V52y",
	"row": "_1B0YO"
};