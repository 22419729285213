import React from 'react';
// import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './LandingPageCaption.scss';
import RequestDemoButton from '../../RequestDemoButton';

class LandingPageCaption extends React.Component {
  render() {
    return (
      <div className={s.landingPageCaption}>
        Individual learning <br /> recommendations for your <br /> students
        <RequestDemoButton isTopNav={false} />
      </div>
    );
  }
}

export default withStyles(s)(LandingPageCaption);
