exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1JqQQ{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;position:absolute;top:0;left:0;width:100%;min-height:100%;background:rgba(0,0,0,.5);z-index:9999;overflow:hidden}._1JqQQ span,._2Dl_Q{z-index:10000}._2Dl_Q{display:block;width:100%}._2Dl_Q [class^=Spinner-]{border-top:1.1em solid hsla(0,0%,100%,.5);border-right:1.1em solid hsla(0,0%,100%,.5);border-bottom:1.1em solid hsla(0,0%,100%,.5);border-left:1.1em solid #0779e4}._3y7MD{display:block;width:100%;color:#fff;text-align:center}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"loading": "_1JqQQ",
	"spinnerWrapper": "_2Dl_Q",
	"loadingText": "_3y7MD"
};