/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import SyntaxHighlighter from 'react-syntax-highlighter';

import {
  codeStyle,
  exampleHeader,
  exampleRequest,
  exampleRequestBulk,
  exampleResponse,
  exampleResponseBulk,
} from './codeBlocks';

import s from './ApiDocs.scss';

const danubeEducation = (
<span
  style={{
    fontWeight: '800',
    fontFamily: "'Gotham Rounded A', sans-serif",
  }}
>
  danube.education
</span>)

class ApiDocs extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    // logout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    me: null,
  };

  render() {
    return (
      <div className={s.apiDocsContainer}>
        <div className={s.apiDocsBackdropWrapper}>
          <div className={s.apiContent}>
            <p>
              <h1 className= {s.withSerif} >danube.education API</h1>
              The {danubeEducation} API suggests an optimal set of tasks
              based on an AI-powered analysis of both the student&#39;s performance history and the study content.
              <br />
              <br />
              For example: From a set of 20 tasks, {danubeEducation} is asked to
              suggest task-groups consisting of 8 or less tasks to the student.
              After the completion of each group, a summary of the student&#39;s
              performance can be posted to {danubeEducation}&#39;s{' '}
              <i>get-tasks</i>
              -endpoint. {danubeEducation} will take both the student&#39;s
              abilities, as well as the learning-paths of
              similarly-performing peers into account and calculate the next set of
              tasks that optimizes the student&#39;s learning curve,
              eventually ceasing to repeat or suggest new tasks when the student has
              performed well enough.
            </p>
            <p>
              <h2>Use API</h2>
              Every request ist sent as a POST-request containing a 
              JSON body with information about the most recently 
              completed group of tasks. In order to use the endpoint, 
              a {danubeEducation} API key is needed. It has to be set in each POST-request&#39;s 
              header like in the example below.
              <br />
              <br />
              <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
                {exampleHeader}
              </SyntaxHighlighter>
            </p>
            <p>
              <h2>Request</h2>
              The request&#39;s JSON body has to be formatted in the
              following structure:
              <br />
              <br />
              <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
                {exampleRequest}
              </SyntaxHighlighter>
              <br />
              <ul>
                <li>
                  <b>&#34;uniqueIdentifier&#34;:</b> The value of this property
                  must be a unique string for each set of study-material, 
                  task-pools and students whose data should be correlated. The 
                  education provider may use several different uniqueIdentifiers, 
                  but only one may be set for each request.
                </li>
                <li>
                  <b>&#34;requests&#34;:</b> A list of formatted data, one per student, instructing the API to
                  either initialize a new sequence of tasks with a given task-pool, or to continue
                  suggesting new tasks, given the student&#39;s performance on the previous task-group.
                </li>
                <ul>
                  <li>
                    <b>&#34;type&#34;:</b> Two types of request are possible: <i>initialize</i> will set up the the API
                    to start a new sequence of tasks for the student, with a given task-pool. <i>continue</i> will 
                    communicate updates on the student&#39;s performance to the API, which will incorporate them
                    in its next suggestions. 
                  </li>
                  <li>
                    <b>&#34;courseId&#34;:</b> The identification of the course, to which the task-pool belongs. One course can
                    hold several, overlapping task-pools. This Id must be unique within the same-type identifications
                    communicated by the education provider.
                  </li>
                  <li>
                    <b>&#34;poolId&#34;:</b> The identification of the set of tasks, from which the AI makes suggestions. 
                    This must remain the same for the entire duration of a student&#39;s interaction with 
                    this task-pool&#39;s material. It must be unique within the poolIds that the course comprises.
                  </li>
                  <li>
                    <b>&#34;userId&#34;:</b> The identification of the student/user. It must be unique.
                  </li>
                  <li>
                    <b>&#34;cycle&#34;:</b> Starts with <i>0</i>, ascending.
                    {danubeEducation} provides a feature to reset a
                    student&#39;s perfomance history on a task-pool, 
                    without forgetting all of his/her achievements. When a new cycle
                    begins, {danubeEducation} will re-suggest
                    tasks that the student has already seen and
                    completed, leaving out those that the student has performed
                    particularly well on in the previous cycle. 
                  </li>
                  <li>
                    <b>&#34;tasks&#34;:</b> A list of data-objects, each providing information 
                    on the performance on one task.
                    <ul>
                      <li>
                        <b>&#34;taskId&#34;:</b> The ID of the task, must be unique across all courses
                        and task-pools.
                      </li>
                      <li>
                        <b>&#34;score&#34;:</b> The performance on the task, as a float [0,1].
                      </li>
                      <li>
                        <b>&#34;postion&#34;:</b> Information about the successive order in which the tasks in the task-group
                        in question were given. This information will help danube detect sequences which will 
                        facilitate the student&#39;s progress on the study-material.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>&#34;timestamp&#34;:</b> The time of the event&#39;s
                    completion.
                  </li>
                  <li>
                    <b>&#34;taskPool&#34;:</b> A list of <i>taskIds</i>,
                    from which the returned suggestions for the next task-group is
                    expected.
                  </li>
                  <li>
                    <b>&#34;limits&#34;:</b> Sets the maximum
                    number of returned tasks expected.
                    <ul>
                      <li>
                        <b>&#34;max&#34;:</b> The maximum number of
                        tasks to be returned. Can be set to{' '}
                        <i>null</i> and will then default to <i>10</i>.
                      </li>
                    </ul>
                  </li>
                  <li>
                  <b>&#34;ccar&#34;:</b> This variable is short 
                  for &#34;consecutive correct answers required&#34;. This value instructs
                  the API on how often a student should consecutively succeed at a task to be considered proficient.
                  </li>
                  <li>
                  <b>&#34;roundupCycle&#34;:</b> Different from a regular cycle, the round up cycle 
                  will reset the students performance history. The API will present the student with all tasks from the 
                  task-pool once and calculate a score from  his/her performance. 
                  This feature is intended to test the student&#39;s progress after
                  several practice cycles. The value is Boolean and has to be set to <i>True</i> for the duration 
                  of the round up cycle and <i>False</i> for all other cycles.
                  </li>
                </ul>
              </ul>

              <br />
              If only a single request is posted to {danubeEducation}, the
              formatted POST-request will look similar to the one shown
              above. In the case of requesting multiple requests to be processed at once,
              the posted data will look similar to what is shown below:
              <br />
              <br />
              <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
                {exampleRequestBulk}
              </SyntaxHighlighter>
              <br />
            </p>
            <p>
              <h2>Response</h2>
              {danubeEducation} will compute a list of <i>taskIds</i>, selected from
              the indicated  <i>taskPool</i>. The response for one request 
              and <i>userId</i> will be structured as follows. If only a single request has been 
              transmitted, the response will be structured as shown below.
              <br />
              <br />
              <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
                {exampleResponse}
              </SyntaxHighlighter>
              <br />
              In the case that the transmitted data contains multiple requests, the returned data 
              will be a list of such responses, identified by <i>userId</i>.
              <br />
              <br />
              <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
                {exampleResponseBulk}
              </SyntaxHighlighter>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ApiDocs);
