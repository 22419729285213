import { defineMessages } from 'react-intl';

const messages = defineMessages({
  globalHeader: {
    id: 'FAQ.globalHeader',
    defaultMessage: 'FAQ',
    description: 'Header for FAQ-Page',
  },
  header1: {
    id: 'FAQ.header1',
    defaultMessage: 'How does it work?',
    description: 'Header for FAQ-Page',
  },
  text1: {
    id: 'FAQ.text1',
    defaultMessage: `
    When you interact with the learning-content by taking lessons, 
    answering questions or solving problems, <a0>danube.education</a0> reviews your progress and
    presents you with the optimal set of tasks with which to continue.
    It can be adapted to any course content and already existing list of tasks. 
    With a rich set of features, your learning experience can be tweaked for optimal efficieny. 
    Work smarter, not harder, eh?
    `,
    description: 'Text for FAQ-Page',
  },
  header2: {
    id: 'FAQ.header2',
    defaultMessage: 'Do you save user data?',
    description: 'Header for FAQ-Page',
  },
  text2: {
    id: 'FAQ.text2',
    defaultMessage: `
      <a0>danube.education</a0> only retains the data it needs for computing the next set of tasks, anonymized, without any reference to any user.
      `,
    description: 'Text for FAQ-Page',
  },
  header4: {
    id: 'FAQ.header4',
    defaultMessage: 'I’m a teacher and I would like to use danube.education.',
    description: 'Header for FAQ-Page',
  },
  text4_1: {
    id: 'learnMore.text4_1',
    defaultMessage: `
      If you’re a teacher, we have a <a1>moodle-plugin</a1> that you can use with your already 
      created content to create wonderfully adaptive “FlexQuizzes” for your students. 
      <a0>danube.education</a0> is also already integrated in our <a2>Waltzing Atoms</a2> chemistry learning app.
    `,
    description: 'Text for learn-more page',
  },
  text4_2: {
    id: 'learnMore.text4_2',
    defaultMessage: `
      If you are an educator of any kind and want to work with us to integrate the 
      technology in your product,
      <a1>let us know</a1>!
    `,
    description: 'Text for learn-more page',
  },
  header5: {
    id: 'FAQ.header5',
    defaultMessage:
      'I’m a developer and I would like to integrate this into my software.',
    description: 'Header for FAQ-Page',
  },
  text5: {
    id: 'FAQ.text5',
    defaultMessage: `
    With our <a2>API</a2> you can integrate <a0>danube.education</a0> in a matter of minutes. 
    Please, <a1>get in touch</a1> if you need any help or if you want to aquire an API license.
    `,
    description: 'Text for FAQ-Page',
  },
});

export default messages;
