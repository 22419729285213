import LandingPage from '../components/LandingPage';
import ApiDocs from '../components/ApiDocs';
import ShowcaseMoodle from '../components/ShowcaseMoodle';
import LearnMore from '../components/LearnMore';
import FAQ from '../components/FAQ';

const routes = [
  {
    path: '/',
    component: LandingPage,
    exact: true,
  },
  {
    path: '/api',
    component: ApiDocs,
    exact: true,
  },
  {
    path: '/moodle-plugin',
    component: ShowcaseMoodle,
    exact: true,
  },
  {
    path: '/learn-more',
    component: LearnMore,
    exact: true,
  },
  {
    path: '/faq',
    component: FAQ,
    exact: true,
  },
];

export default routes;
