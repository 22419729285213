import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';

import s from './showcase.scss'; //eslint-disable-line

class ShowcaseTemplate extends React.Component {
  static propTypes = {
    pages: PropTypes.isRequired,
  };

  render() {
    return (
      <div className={s.showcaseTemplate}>
        {this.props.pages.map((page, i) => (
          <div id={`page_${i + 1}`} className={s.showcasePage}>
            {page}
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(s)(ShowcaseTemplate);
