// import { styles } from 'ansi-colors';
import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'redux';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';

import { Alert, SuccessAlert } from '../Modals/InfoModal';
import messages from './messages';
import s from './RequestDemoButton.scss'; // eslint-disable-line css-modules/no-unused-class
import validations from './validations';
import googleReCaptchaKey from '../../../constants/googleReCaptchaKey';

const validate = values => {
  const errors = {};
  if (!validations.required(values.emailAddress)) {
    errors.emailAddress = 'is required';

    /* <FormattedMessage {...validationMessages.required} /> */
  }
  if (!validations.required(values.reCaptchaValue)) {
    errors.reCaptchaValue = 'is required';
  }
  if (!validations.email(values.emailAddress)) {
    errors.emailAddress = 'is not valid';
  }
  return errors;
};

class RequestDemoButton extends React.Component {
  static propTypes = {
    isTopNav: PropTypes.bool.isRequired,
    text: PropTypes.string,
    isLink: PropTypes.bool,
  };

  static defaultProps = {
    text: null,
    isLink: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      reCaptchaValue: null,
      emailAddress: null,
      success: false,
      error: false,
      formErrors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onChange(value) {
    this.setState({ reCaptchaValue: value });
  }

  handleChange(event) {
    this.setState({ emailAddress: event.target.value });
  }

  hideModal(e) {
    if (e) e.preventDefault();
    this.setState({
      reCaptchaValue: '',
      emailAddress: '',
      requestModalIsVisible: false,
      formErrors: {},
      success: false,
      error: false,
    });
  }

  showModal() {
    this.setState({
      requestModalIsVisible: true,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { reCaptchaValue, emailAddress } = this.state;
    const { requestDemoMail } = this.props;
    const formErrors = validate({ reCaptchaValue, emailAddress });
    if (Object.keys(formErrors).length > 0) {
      await this.setState({ formErrors });
      return;
    }
    try {
      const res = await requestDemoMail({ reCaptchaValue, emailAddress });
      if (res.data.requestDemoMail) {
        this.setState({ success: res.data.requestDemoMail });
      } else {
        this.setState({ error: true });
      }
    } catch (err) {
      console.info(err);
    }
  }

  render() {
    const { text, isLink } = this.props;
    const { formErrors, success, error, requestModalIsVisible } = this.state;
    const buttonStyle = this.props.isTopNav
      ? `btn-orange btn-round ${s.requestDemoButton}`
      : `btn-orange btn-high btn-large ${s.requestButton}`;

    const wrapperClass = this.props.isTopNav
      ? s.navBarButtonWrapper
      : s.requestDemoButtonWrapper;
    return (
      <div
        className={wrapperClass}
        style={{ display: isLink ? 'inline' : 'block' }}
      >
        {isLink ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href=""
            onClick={e => {
              e.preventDefault();
              this.showModal();
            }}
          >
            {text || 'Get Started'}
          </a>
        ) : (
          <button className={buttonStyle} onClick={this.showModal}>
            {text || 'Get Started'}
          </button>
        )}

        <Alert
          show={requestModalIsVisible}
          title="Enter your e-mail and we'll get back to you"
          body={
            <div>
              <form className={s.form} onSubmit={this.handleSubmit}>
                <label htmlFor="email">
                  <input
                    id="email"
                    name="email"
                    placeholder="email"
                    value={this.state.value}
                    onChange={this.handleChange}
                  />
                </label>
                {formErrors.emailAddress && (
                  <span className={s.validationError}>
                    {formErrors.emailAddress}
                  </span>
                )}
                <div className={s.captchaWrapper}>
                  <ReCAPTCHA
                    sitekey={googleReCaptchaKey}
                    onChange={this.onChange}
                  />
                </div>
                {formErrors.reCaptchaValue && (
                  <span className={s.validationError}>
                    {formErrors.reCaptchaValue}
                  </span>
                )}
                <div className={s.buttonRow}>
                  <button
                    className={`btn-primary btn-round ${s.CancelButton}`}
                    onClick={this.hideModal}
                  >
                    <FormattedMessage {...messages.cancel} />
                  </button>
                  <button
                    type="submit"
                    className={`btn-secondary btn-round ${s.SubmitButton}`}
                  >
                    <FormattedMessage {...messages.submit} />
                  </button>
                </div>
              </form>
              <SuccessAlert
                show={success}
                title={<FormattedMessage {...messages.alertSuccessTitle} />}
                body={<FormattedMessage {...messages.alertSuccessMessage} />}
                footer={
                  <button
                    className="btn-white-rnd btn-round"
                    onClick={this.hideModal}
                  >
                    Close
                  </button>
                }
                hide={this.hideModal}
              />
              <Alert
                show={error}
                title={<FormattedMessage {...messages.alertErrorTitle} />}
                body={<FormattedMessage {...messages.alertErrorMessage} />}
                hide={this.hideModal}
              />
            </div>
          }
          hide={this.hideModal}
        />
      </div>
    );
  }
}

RequestDemoButton.propTypes = {
  requestDemoMail: PropTypes.func.isRequired,
  baseButtonStyle: PropTypes.string.isRequired,
};

const requestDemoMail = gql`
  mutation requestDemoMail($mailData: MailData) {
    requestDemoMail(mailData: $mailData)
  }
`;

export default compose(
  withStyles(s),
  graphql(requestDemoMail, {
    props: ({ mutate }) => ({
      requestDemoMail: mailData =>
        mutate({
          variables: { mailData },
        }),
    }),
  }),
)(RequestDemoButton);
