import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import gql from 'graphql-tag';
import s from './LandingPage.scss';
import LandingPageCaption from './LandingPageCaption';
// import RequestDemoButton from '../RequestDemoButton';
import SponsorLogo from './SponsorLogo';

class LandingPage extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    // logout: PropTypes.func.isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static defaultProps = {
    me: null,
  };

  render() {
    return [
      <>
        <div className={s.landingPageContainer} key="landingPage">
          <LandingPageCaption key="app-landing-page-caption" />
          <SponsorLogo />
        </div>
      </>,
    ];
  }
}

export default withStyles(s)(LandingPage);
