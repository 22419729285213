import React from 'react';
import { Row, Col } from 'react-bootstrap';

class NotFound extends React.Component {
  render() {
    return (
      <Row>
        <Col xs={12}>
          404 - Sorry, the page you were trying to view does not exist.
        </Col>
      </Row>
    );
  }
}

export default NotFound;
