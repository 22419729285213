import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { init as SentryInit } from '@sentry/browser';

const sentryQuery = gql`
  query sentry {
    sentry_data {
      dsn
      environment
      release
    }
  }
`;

let sentryInitialized = false;
let sentryInitializing = false;

function initSentry({ dsn, environment, release }) {
  if (dsn && dsn.length > 10) {
    SentryInit({
      dsn,
      environment,
      release,
    });
  }
}

class SentryWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    client: PropTypes.shape({
      query: PropTypes.func.isRequired,
    }).isRequired,
    WrappedComponent: PropTypes.node,
  };

  static defaultProps = {
    WrappedComponent: null,
  };

  constructor(props) {
    super(props);
    const { client } = this.props;
    if (!__DEV__ && !sentryInitialized && !sentryInitializing) {
      sentryInitializing = true;
      client
        .query({
          query: sentryQuery,
          fetchPolicy: 'network-only',
        })
        // eslint-disable-next-line camelcase
        .then(({ data: { sentry_data }, loading }) => {
          if (!loading) {
            initSentry(sentry_data);
            sentryInitialized = true;
            sentryInitializing = false;
          }
        })
        .catch(() => {
          sentryInitialized = false;
          sentryInitializing = false;
        });
    }
  }

  render() {
    const { WrappedComponent, children } = this.props;
    if (!WrappedComponent) {
      return React.Children.only(children);
    }
    return <WrappedComponent {...this.props} />;
  }
}

export { sentryQuery, SentryWrapper };
