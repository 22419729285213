exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3sP-J{display:block;width:100%}._3sP-J ._3-bSB{padding:50px 200px}@media (max-width:1200px){._3sP-J ._3-bSB{padding:15px 15px 50px}}._3sP-J ._3-bSB ._1M9ai{font-family:Gotham SSm A,Gotham SSm B,HelveticaNeue-light,sans-serif;font-size:20px;background:-webkit-radial-gradient(2.03% 9.49%,96.09% 2204.51%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);background:-o-radial-gradient(2.03% 9.49%,96.09% 2204.51%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);background:radial-gradient(96.09% 2204.51% at 2.03% 9.49%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);border-radius:12px;padding:50px}@media (max-width:768px){._3sP-J ._3-bSB ._1M9ai{padding:15px}}._3sP-J ._3-bSB ._1M9ai h1{margin:0 0 10px;padding:0;font-size:40px}._3C__w{font-family:Archer SSm A,Archer SSm B,Archer,Times New Roman,serif}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"apiDocsContainer": "_3sP-J",
	"apiDocsBackdropWrapper": "_3-bSB",
	"apiContent": "_1M9ai",
	"withSerif": "_3C__w"
};