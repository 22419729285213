exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2jq8L{font-style:normal;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;position:fixed;font-size:20px;left:0;bottom:0;height:74px;width:100%;background:hsla(0,0%,100%,.75);color:#333;text-align:center;z-index:1000}@supports ((-webkit-backdrop-filter:none) or (backdrop-filter:none)){._2jq8L{background:-webkit-radial-gradient(2.03% 9.49%,96.09% 2204.51%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);background:-o-radial-gradient(2.03% 9.49%,96.09% 2204.51%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);background:radial-gradient(96.09% 2204.51% at 2.03% 9.49%,hsla(0,0%,100%,.49) 0,hsla(0,0%,100%,.35) 100%);-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}}@media (max-width:768px){._2jq8L{height:200px;-ms-flex-align:initial;align-items:initial;display:block}}._2jq8L button{font-style:normal;padding:10px 30px;border:2px solid #1264e4;border-radius:0;background-color:#1264e4;font-size:20px;font-weight:400;outline:none;position:absolute;right:15px;color:#fff}@media (max-width:768px){._2jq8L button{position:relative;width:90%;right:0;margin:0 auto;border:none}}._2jq8L span{display:-ms-flexbox;display:flex;position:absolute;text-align:left;left:15px;width:70%;-ms-flex-align:top;align-items:top}@media (max-width:768px){._2jq8L span{margin:15px;margin-bottom:20px;position:relative;width:calc(100vw - 30px)}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"cookieBanner": "_2jq8L"
};