import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import TwoColumnPage from '../TwoColumnPage';
import messages from './messages';
import { contactEmail } from '../../../constants/email';

const danubeEducation = {
  a0: (...chunks) => (
    <span
      style={{
        fontWeight: '800',
        fontFamily: "'Gotham Rounded A', sans-serif",
      }}
    >
      {chunks.join('')}
    </span>
  ),
};

class FAQ extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    intl: PropTypes.any.isRequired,
  };

  render() {
    const { intl } = this.props;

    return (
      <TwoColumnPage
        leftColumnContent={
          <div>
            <h1>
              <FormattedMessage {...messages.globalHeader} />
            </h1>
          </div>
        }
        rightColumnContent={
          <div>
            <h2>
              <FormattedMessage {...messages.header1} />
            </h2>
            <p>{intl.formatMessage(messages.text1, danubeEducation)}</p>
            <h2>
              <FormattedMessage {...messages.header2} />
            </h2>
            <p>{intl.formatMessage(messages.text2, danubeEducation)}</p>
            <h2>{intl.formatMessage(messages.header4)}</h2>
            <p>
              {intl.formatMessage(messages.text4_1, {
                a0: (...chunks) => (
                  <span
                    style={{
                      fontWeight: '800',
                      fontFamily: "'Gotham Rounded A', sans-serif",
                    }}
                  >
                    {chunks.join('')}
                  </span>
                ),
                a1: (...chunks) => (
                  <Link to="/moodle-plugin">{chunks.join('')}</Link>
                ),
                a2: (...chunks) => (
                  <a
                    href="https://www.waltzingatoms.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks.join('')}
                  </a>
                ),
              })}
            </p>
            <p>
              {intl.formatMessage(messages.text4_2, {
                a1: (...chunks) => (
                  <a href={`mailto:${contactEmail}`}>{chunks.join('')}</a>
                ),
              })}
            </p>
            <h2>
              <FormattedMessage {...messages.header5} />
            </h2>
            <p>
              {intl.formatMessage(messages.text5, {
                a0: (...chunks) => (
                  <span
                    style={{
                      fontWeight: '800',
                      fontFamily: "'Gotham Rounded A', sans-serif",
                    }}
                  >
                    {chunks.join('')}
                  </span>
                ),
                a1: (...chunks) => (
                  <a href={`mailto:${contactEmail}`}>{chunks.join('')}</a>
                ),
                a2: (...chunks) => <Link to="/api">{chunks.join('')}</Link>,
              })}
            </p>
          </div>
        }
      />
    );
  }
}

export default injectIntl(FAQ);
